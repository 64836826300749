export interface Ref {
  id: bigint;
  createdAt: Date;
}

export enum RefStage {
  Local = "local",
  Shared = "shared",
  Develop = "develop",
  Demo = "demo",
  Staging = "staging",
  Production = "production",
}

export enum GlobalPrivilege {
  // Auth
  Auth_Grant = "AUTH::GRANT", // Auth 변경 및 모든 권한
  Auth_View = "AUTH::VIEW", // Auth 관리 페이지 Viewer 사용권한

  // Admin
  Admin_User_Create = "ADMIN::USER:CREATE",
  Admin_User_UnMasked = "ADMIN::USER:UNMASKED",
  Admin_Comments_Edit = "ADMIN::COMMENTS:EDIT",
  Admin_Release_Edit = "ADMIN::RELEASE:EDIT",
  Admin_Notice_Add = "ADMIN::NOTICE:ADD",
  Admin_Notice_Edit = "ADMIN::NOTICE:EDIT",
  Admin_Current_Subscription_Edit = "ADMIN::CURRENT_SUBSCRIPTION:EDIT",
  Admin_Next_Billing_Date_Edit = "ADMIN::NEXT_BILLING_DATE:EDIT",
  Admin_Organization_Dictionary_License_Edit = "ADMIN::ORGANIZATION:DICTIONARY_LICENSE:EDIT",
  Admin_Organization_View = "ADMIN::ORGANIZATION:VIEW",
  Admin_Organization_List_View = "ADMIN::ORGANIZATION_LIST:VIEW",
  Admin_Organization_Name_Edit = "ADMIN::ORGANIZATION_NAME:EDIT",
  Admin_Organization_Owner_Edit = "ADMIN::ORGANIZATION_OWNER:EDIT",
  Admin_Organization_Payment_Edit = "ADMIN:ORGANIZATION_PAYMENT:EDIT",
  Admin_Organization_Purchase_Edit = "ADMIN::ORGANIZATION_PURCHASE:EDIT",
  Admin_Subscription_Supervisor = "ADMIN::SUBSCRIPTION:SUPERVISOR",
  Admin_Subscription_View = "ADMIN::SUBSCRIPTION:VIEW",
  Admin_Subscription_Edit = "ADMIN::SUBSCRIPTION:EDIT",
  Admin_Subscription_CleanUp_Date_Edit = "ADMIN::SUBSCRIPTION_CLEANUP_DATE:EDIT",
  Admin_Subscription_History_View = "ADMIN::SUBSCRIPTION_HISTORY:VIEW",
  Admin_Audit_Trail_View = "ADMIN::AUDIT_TRAIL:VIEW",
  Admin_Property_View = "ADMIN::PROPERTY:VIEW",
  Admin_Property_Edit = "ADMIN::PROPERTY:EDIT",
  Admin_AutomationTest = "ADMIN::AUTOMATION_TEST",

  // Admin-Safety
  Admin_Safety_Workspace_View = "ADMIN::SAFETY:WORKSPACE:VIEW",
  Admin_Safety_Workspace_Edit = "ADMIN::SAFETY:WORKSPACE:EDIT",
  Admin_Safety_Template_View = "ADMIN::SAFETY:TEMPLATE:VIEW",
  Admin_Safety_Manage_Gateway_View = "ADMIN::SAFETY:MANAGE_GATEWAY:VIEW",
  Admin_Safety_Manage_Gateway_Edit = "ADMIN::SAFETY:MANAGE_GATEWAY:EDIT",

  // Project
  Project_Project_Supervisor = "PROJECT::PROJECT:SUPERVISOR", // Project Supervisor
  Project_Folder_Supervisor = "PROJECT::FOLDER:SUPERVISOR", // Folder Supervisor

  // Docs
  Docs_Viewer = "DOCS::VIEWER", // Viewer 사용권한
  Docs_Sign = "DOCS::SIGN", // eSign 사용권한
  Docs_Composer = "DOCS::COMPOSER", // Composer 사용권한
  Docs_Composer_Supervisor = "DOCS::COMPOSER:SUPERVISOR", // Composer Supervisor
  Docs_Template = "DOCS::TEMPLATE", // Composer - Template 사용권한

  // Builder
  Builder_Supervisor = "BUILDER::SUPERVISOR", // Builder Supervisor
  Builder_Payload_Export = "BUILDER::PAYLOAD_EXPORT", // Builder Payload Export 사용권한

  // Safety
  Safety_Supervisor = "SAFETY::SUPERVISOR", // Safety Supervisor
}

export enum RefServiceType {
  Suite = "SUITE",
  Resource = "RESOURCE",
  SSO = "SSO",
  Portal = "PORTAL",
  ERecruitment = "ERECRUITMENT",
  Auth = "AUTH",
  Admin = "ADMIN",
  Project = "PROJECT",
  Billing = "BILLING",
  Docs = "DOCS",
  Tmf = "TMF",
  DocsPdf = "DOCS_PDF",
  Builder = "BUILDER",
  Validator = "VALIDATOR",
  Safety = "SAFETY",
  OCR = "OCR",
  Paths = "PATHS",
  Cdms = "CDMS",
  CdmsStudy = "CDMS_STUDY",
  CdmsDw = "CDMS_DW",
  Vdr = "VDR",
  Converter = "CONVERTER",
  ConverterValidator = "CONVERTER_VALIDATOR",
  X = "X",
}

export enum RefResourceType {
  // Common
  Common_EventPubQueue = "COMMON::EVENT_PUB_QUEUE",
  Common_EventSubQueue = "COMMON::EVENT_SUB_QUEUE",
  Common_EventPullQueue = "COMMON::EVENT_PULL_QUEUE",

  // Auth
  Auth_User = "AUTH::USER",
  Auth_UserInfo = "AUTH::USER_INFO",
  Auth_UserPrivilege = "AUTH::USER_PRIVILEGE",
  Auth_UserVerificationCode = "AUTH::USER_VERIFICATION_CODE",
  Auth_Organization = "AUTH::ORGANIZATION",
  Auth_Organization_Dictionary_License = "AUTH::ORGANIZATION_DICTIONARY_LICENSE",
  Auth_Member = "AUTH::MEMBER",
  Auth_MemberInvitation = "AUTH::MEMBER_INVITATION",
  Auth_Role = "AUTH::ROLE",
  Auth_UserRole = "AUTH::USER_ROLE",
  Auth_SalesInquiry = "AUTH::SALES_INQUIRY",
  Auth_Notification = "AUTH::NOTIFICATION",

  // Admin
  Admin_Comment = "ADMIN::COMMENT",
  Admin_Property = "ADMIN::PROPERTY",
  Admin_Notice = "ADMIN::NOTICE",
  Admin_NoticeContent = "ADMIN::NOTICE_CONTENT",
  Admin_Release = "ADMIN::RELEASE",
  Admin_ReleaseContent = "ADMIN::RELEASE_CONTENT",

  // SSO
  SSO_AuthToken = "SSO::AUTH_TOKEN",
  SSO_RequestToken = "SSO::REQUEST_TOKEN",
  SSO_Notification = "SSO::NOTIFICATION",
  SSO_UserNotification = "SSO::USER_NOTIFICATION",
  SSO_UserVerificationCode = "SSO::USER_VERIFICATION_CODE",
  SSO_UserAuthLog = "SSO::USER_AUTH_LOG",
  SSO_UserDevice = "SSO::USER_DEVICE",
  SSO_UserDeviceVerificationCode = "SSO::USER_DEVICE_VERIFICATION_CODE",

  // Resource
  Resource_PrivilegeGroup = "RESOURCE::PRIVILEGE_GROUP",
  Resource_Resource = "RESOURCE::RESOURCE",
  Resource_ResourcePrivilege = "RESOURCE::RESOURCE_PRIVILEGE",

  // Project
  Project_Project = "PROJECT::PROJECT",
  Project_Folder = "PROJECT::FOLDER",
  Project_FolderVariable = "PROJECT::FOLDER_VARIABLE", // Folder의 종속 리소스로 별도 권한 없음
  Project_OrgFolder = "PROJECT::ORG_FOLDER",

  // Billing
  Billing_BillingKey = "BILLING::BILLING_KEY",
  Billing_Bundle = "BILLING::BUNDLE",
  Billing_BundleItem = "BILLING::BUNDLE_ITEM",
  Billing_BundlePrice = "BILLING::BUNDLE_PRICE",
  Billing_BundleOrganizationPrice = "BILLING::BUNDLE_ORGANIZATION_PRICE",
  Billing_Subscription = "BILLING::SUBSCRIPTION",
  Billing_SubscriptionLicense = "BILLING::SUBSCRIPTION_LICENSE",
  Billing_SubscriptionCleanUp = "BILLING::SUBSCRIPTION_CLEAN_UP",
  Billing_Inventory = "BILLING::INVENTORY",
  Billing_InventoryUsage = "BILLING::INVENTORY_USAGE",
  Billing_Invoice = "BILLING::INVOICE",
  Billing_Transaction = "BILLING::TRANSACTION",
  Billing_SubscriptionStatusNoti = "BILLING::SUBSCRIPTION_STATUS_NOTI",
  Billing_NotificationMail = "BILLING::NOTIFICATION_MAIL",

  // Template
  Docs_Template = "DOCS::TEMPLATE",
  Docs_TemplateGrant = "DOCS::TEMPLATE_GRANT",

  // Folder
  Docs_Folder = "DOCS::FOLDER",
  Docs_FolderVariable = "DOCS::FOLDER_VARIABLE", // Folder의 종속 리소스로 별도 권한 없음
  Docs_FolderFile = "DOCS::FOLDER_FILE",
  Docs_FolderSharing = "DOCS::FOLDER_SHARING",
  Docs_FolderSharingSetting = "DOCS::FOLDER_SHARING_SETTING",
  Docs_FolderSharingIpWhitelist = "DOCS::FOLDER_SHARING_IP_WHITELIST",
  Docs_FolderSharingLink = "DOCS::FOLDER_SHARING_LINK",

  // Proposal
  Docs_Proposal = "DOCS::PROPOSAL",
  Docs_ProposalVersion = "DOCS::PROPOSAL_VERSION", // ProposalVersion의 종속 리소스로 별도 권한 없음
  Docs_ProposalSection = "DOCS::PROPOSAL_SECTION", // ProposalVersion의 종속 리소스로 별도 권한 없음
  Docs_ProposalTitle = "DOCS::PROPOSAL_TITLE", // ProposalVersion의 종속 리소스로 별도 권한 없음
  Docs_ProposalContent = "DOCS::PROPOSAL_CONTENT", // ProposalVersion의 종속 리소스로 별도 권한 없음
  Docs_ProposalComment = "DOCS::PROPOSAL_COMMENT", // ProposalVersion의 종속 리소스로 별도 권한 없음

  // Sign
  Docs_Sign = "DOCS::SIGN",
  Docs_SignAttr = "DOCS::SIGN_ATTR", // 종속 리소스로 별도 권한 없음
  Docs_SignToken = "DOCS::SIGN_TOKEN", // 종속 리소스로 별도 권한 없음
  Docs_Signer = "DOCS::SIGNER",

  // Document
  Docs_Document = "DOCS::DOCUMENT",
  Docs_DocumentSign = "DOCS::DOCUMENT_SIGN",
  Docs_DocumentSigner = "DOCS::DOCUMENT_SIGNER", // 종속 리소스로 별도 권한 없음
  Docs_DocumentSignerAttr = "DOCS::DOCUMENT_SIGNER_ATTR", // 종속 리소스로 별도 권한 없음
  Docs_DocumentSignerHistory = "DOCS::DOCUMENT_SIGNER_HISTORY", // 종속 리소스로 별도 권한 없음
  Docs_DocumentTrainingRecord = "DOCS::DOCUMENT_TRAINING_RECORD",
  Docs_FileExportHistory = "DOCS::FILE_EXPORT_HISTORY",
  Docs_DocumentSignSchedule = "DOCS::DOCUMENT_SIGN_SCHEDULE",
  Docs_DocumentSignerSchedule = "DOCS::DOCUMENT_SIGNER_SCHEDULE",

  // E-Consent
  Docs_EconsentConfigure = "DOCS::ECONSENT_CONFIGURE",
  Docs_EconsentHistory = "DOCS::ECONSENT_HISTORY",
  Docs_EconsentSignerHistory = "DOCS::ECONSENT_SIGNER_HISTORY",

  // E-TMF
  Tmf_Country = "TMF::COUNTRY",
  Tmf_Study = "TMF::STUDY",
  Tmf_StudyCountry = "TMF::STUDY_COUNTRY",
  Tmf_StudySite = "TMF::STUDY_SITE",
  Tmf_StudyUser = "TMF::STUDY_USER",
  Tmf_StudyRole = "TMF::STUDY_ROLE",
  Tmf_StudyRolePrivs = "TMF::STUDY_ROLE_PRIVILEGES",
  Tmf_StudyTemplate = "TMF::STUDY_TEMPLATE",
  Tmf_StudyStructure = "TMF::STUDY_STRUCTURE",
  Tmf_StudyMilestone = "TMF::STUDY_MILESTONE",
  Tmf_StudyMilestoneTimeline = "TMF::STUDY_MILESTONE_TIMELINE",
  Tmf_StudyMilestoneEssentialDocument = "TMF::STUDY_MILESTONE_ESSENTIAL_DOCUMENT",
  Tmf_StudyZone = "TMF::STUDY_ZONE",
  Tmf_StudySection = "TMF::STUDY_SECTION",
  Tmf_StudyArtifact = "TMF::STUDY_ARTIFACT",
  Tmf_StudySubArtifact = "TMF::STUDY_SUB_ARTIFACT",

  Tmf_StudyDocument = "TMF::STUDY_DOCUMENT",
  Tmf_StudyDocumentVersion = "TMF::STUDY_DOCUMENT_VERSION",
  Tmf_StudyDocumentComment = "TMF::STUDY_DOCUMENT_COMMENT",

  Tmf_StudyDwHistory = "TMF::STUDY_DW_HISTORY",
  Tmf_StudyReport = "TMF::STUDY_REPORT",

  // DocsPDF
  DocsPdf_Pdf = "DOCS_PDF::PDF",
  DocsPdf_CompressionQueue = "DOCS_PDF::COMPRESSION_QUEUE",
  DocsPdf_ConvertQueue = "DOCS_PDF::CONVERT_QUEUE",

  // CDMS
  CDMS_MasterStudy = "CDMS::MASTER_STUDY",
  CDMS_StudyCrf = "CDMS::STUDY_CRF",
  CDMS_DW_History = "CDMS::DW_HISTORY",
  CDMS_DW_TemplateReport = "CDMS::DW_TEMPLATE_REPORT",
  CDMS_StudySite = "CDMS::STUDY_SITE",
  CDMS_Affiliation = "CDMS::AFFILIATION",
  CDMS_StudyIndependentEvaluation = "CDMS::STUDY_INDEPENDENT_EVALUATION",

  CDMS_StudyAlwaysSeriousListUpload = "CDMS::Study_ALWAYS_SERIOUS_LIST_UPLOAD",

  CDMS_SafetyIntegrationUser = "CDMS::SAFETY_INTEGRATION_USER",
  CDMS_SafetyCase = "CDMS::SAFETY_CASE",

  // Safety
  Safety_User = "SAFETY::USER",
  Safety_NotiMail = "SAFETY::NOTI_MAIL",
  Safety_Cache = "SAFETY::CACHE",
  Safety_Crf = "SAFETY::CRF",
  Safety_CrfExtension = "SAFETY::CRF_EXTENSION",
  Safety_CrfOrganization = "SAFETY::CRF_ORGANIZATION",
  Safety_Workspace = "SAFETY::WORKSPACE",
  Safety_WorkspaceItem = "SAFETY::WORKSPACE_ITEM",
  Safety_WorkspaceFolder = "SAFETY::WORKSPACE_FOLDER",
  Safety_WorkspaceMember = "SAFETY::WORKSPACE_MEMBER",
  Safety_WorkspaceMemberRole = "SAFETY::WORKSPACE_MEMBER_ROLE",
  Safety_WorkspaceRole = "SAFETY::WORKSPACE_ROLE",
  Safety_WorkspaceRolePrivilege = "SAFETY::WORKSPACE_ROLE_PRIVILEGE",
  Safety_WorkspaceDictionary = "SAFETY::WORKSPACE_DICTIONARY",
  Safety_WorkspaceCasePrivilege = "SAFETY::WORKSPACE_CASE_PRIVILEGE",
  Safety_WorkspaceDestroyTask = "SAFETY::WORKSPACE_DESTROY_TASK",
  Safety_WorkspaceDwHistory = "SAFETY::WORKSPACE_DW_HISTORY",
  Safety_Case = "SAFETY::CASE",
  Safety_CaseSync = "SAFETY::CASE_SYNC",
  Safety_CaseSyncBuffer = "SAFETY::CASE_SYNC_BUFFER",
  Safety_CaseSyncQueue = "SAFETY::CASE_SYNC_QUEUE",
  Safety_CaseExportUsage = "SAFETY::CASE_EXPORT_USAGE",
  Safety_CaseReport = "SAFETY::CASE_REPORT",
  Safety_CaseReportDictionary = "SAFETY::CASE_REPORT_DICTIONARY",
  Safety_CaseReportRequest = "SAFETY::CASE_REPORT_REQUEST",
  Safety_CaseReportRequestApprover = "SAFETY::CASE_REPORT_REQUEST_APPROVER",
  Safety_CaseReportAdverseEvent = "SAFETY::CASE_REPORT_ADVERSE_EVENT",
  Safety_CaseReportProduct = "SAFETY::CASE_REPORT_PRODUCT",
  Safety_CaseReportSubject = "SAFETY::CASE_REPORT_SUBJECT",
  Safety_CaseReportWorkflow = "SAFETY::CASE_REPORT_WORKFLOW",
  Safety_CaseReportWorkflowAssignee = "SAFETY::CASE_REPORT_WORKFLOW_ASSIGNEE",
  Safety_CaseCapture = "SAFETY::CASE_CAPTURE",
  Safety_CaseComment = "SAFETY::CASE_COMMENT",
  Safety_CaseReportExport = "SAFETY::CASE_REPORT_EXPORT",
  Safety_CaseReportExportDetail = "SAFETY::CASE_REPORT_EXPORT_DETAIL",
  Safety_CaseReportImport = "SAFETY::CASE_REPORT_IMPORT",
  Safety_CaseReportStatus = "SAFETY::CASE_REPORT_STATUS",
  Safety_GatewayCertificate = "SAFETY::GATEWAY_CERTIFICATE",
  Safety_GatewayAs2Account = "SAFETY::GATEWAY_AS2_ACCOUNT",
  Safety_GatewayAs2Connection = "SAFETY::GATEWAY_AS2_CONNECTION",
  Safety_Submission = "SAFETY::SUBMISSION",
  Safety_CaseReportSubmission = "SAFETY::CASE_REPORT_SUBMISSION",

  // OCR
  OCR_Template = "OCR::TEMPLATE",
  OCR_TemplateGrant = "OCR::TEMPLATE_GRANT",
  OCR_Document = "OCR::DOCUMENT",
  OCR_DocumentImage = "OCR::DOCUMENT_IMAGE",

  // Builder
  Builder_Study = "BUILDER::STUDY",
  Builder_StudyTemplate = "BUILDER::STUDY_TEMPLATE",
  Builder_StudyTemplatePayload = "BUILDER::STUDY_TEMPLATE_PAYLOAD",
  Builder_StudyTemplatePayloadRequest = "BUILDER::STUDY_TEMPLATE_PAYLOAD_REQUEST",
  Builder_StudyTemplateUser = "BUILDER::STUDY_TEMPLATE_USER",
  Builder_StudyTitle = "BUILDER::STUDY_TITLE",
  Builder_StudyEcoaConfig = "BUILDER::STUDY_ECOA_CONFIG",
  Builder_StudyPayload = "BUILDER::STUDY_PAYLOAD",
  Builder_StudyPayloadValidation = "BUILDER::STUDY_PAYLOAD_VALIDATION",
  Builder_StudyPayloadPushHistory = "BUILDER::STUDY_PAYLOAD_PUSH_HISTORY",
  Builder_StudyPayloadSyncHistory = "BUILDER::STUDY_PAYLOAD_SYNC_HISTORY",
  Builder_StudyPayloadComment = "BUILDER::STUDY_PAYLOAD_COMMENT",
  Builder_StudyPayloadDomain = "BUILDER::STUDY_PAYLOAD_DOMAIN",
  Builder_StudyPayloadFolder = "BUILDER::STUDY_PAYLOAD_FOLDER",
  Builder_StudyPayloadFolderVisit = "BUILDER::STUDY_PAYLOAD_FOLDER_VISIT",
  Builder_StudyPayloadFolderVisitPage = "BUILDER:STUDY_PAYLOAD_FOLDER_VISIT_PAGE",
  Builder_StudyPayloadPage = "BUILDER::STUDY_PAYLOAD_PAGE",
  Builder_StudyPayloadPageSection = "BUILDER::STUDY_PAYLOAD_PAGE_SECTION",
  Builder_StudyPayloadPageSectionItem = "BUILDER::STUDY_PAYLOAD_PAGE_SECTION_ITEM",
  Builder_StudyRole = "BUILDER::STUDY_ROLE",
  Builder_StudyRoleUpload = "BUILDER::STUDY_ROLE_UPLOAD",
  Builder_StudyRolePrivilege = "BUILDER::STUDY_ROLE_PRIVILEGE",
  Builder_StudyMedicalCoding = "BUILDER::STUDY_MEDICAL_CODING",
  Builder_StudyMedicalCodingColumn = "BUILDER::STUDY_MEDICAL_CODING_COLUMN",
  /** @deprecated CDMS_StudySite 사용 요망 */
  Builder_StudySite = "BUILDER::STUDY_SITE",
  Builder_StudySiteEnvironment = "BUILDER::STUDY_SITE_ENVIRONMENT",
  Builder_StudyDashboardConfig = "BUILDER::STUDY_DASHBOARD_CONFIG",
  Builder_StudyDashboardConfigSubjectStatus = "BUILDER::STUDY_DASHBOARD_CONFIG_SUBJECT_STATUS",
  Builder_StudyDashboardConfigSite = "BUILDER::STUDY_DASHBOARD_CONFIG_SITE",
  Builder_StudyStatusCaptureStatus = "BUILDER::STUDY_STATUS_CAPTURE_STAT",
  Builder_StudyLabTestItem = "BUILDER::STUDY_LAB_TEST_ITEM",
  Builder_StudyLabTestItemUnit = "BUILDER::STUDY_LAB_TEST_ITEM_UNIT",
  Builder_StudyPayloadLabTestItem = "BUILDER::STUDY_PAYLOAD_LAB_TEST_ITEM",
  Builder_StudyPayloadLabTestUnit = "BUILDER::STUDY_PAYLOAD_LAB_TEST_UNIT",
  Builder_StudyPayloadTrigger = "BUILDER::STUDY_PAYLOAD_TRIGGER",
  Builder_StudyPayloadTriggerVersion = "BUILDER::STUDY_PAYLOAD_TRIGGER_VERSION",
  Builder_StudyBuilderUser = "BUILDER::STUDY_BUILDER_USER",
  Builder_StudyBuilderUserRole = "BUILDER::STUDY_BUILDER_USER_ROLE",
  Builder_StudyBuilderRole = "BUILDER::STUDY_BUILDER_ROLE",
  Builder_StudyBuilderRolePrivilege = "BUILDER::STUDY_BUILDER_ROLE_PRIVILEGE",
  Builder_MemberOrganizationRole = "BUILDER::MEMBER_ORGANIZATION_ROLE",
  Builder_OrganizationRole = "BUILDER::ORGANIZATION_ROLE",
  Builder_OrganizationRolePrivilege = "BUILDER::ORGANIZATION_ROLE_PRIVILEGE",
  Builder_TriggerTargetMapping = "BUILDER::TRIGGER_TARGET_MAPPING",

  // Paths
  Paths_ShortenUrl = "PATHS::SHORTEN_URL",

  // eRecruitment
  ERecruitment_User = "ERECRUITMENT::USER",
  ERecruitment_UserInfo = "ERECRUITMENT::USER_INFO",
  ERecruitment_UserPrivilege = "ERECRUITMENT::USER_PRIVILEGE",
  ERecruitment_UserConsentHistory = "ERECRUITMENT::USER_CONSENT_HISTORY",
  ERecruitment_UserVerificationCode = "ERECRUITMENT::USER_VERIFICATION_CODE",
  ERecruitment_UserFavoriteStudy = "ERECRUITMENT::USER_FAVORITE_STUDY",
  ERecruitment_UserWithdrawalReason = "ERECRUITMENT::USER_WITHDRAWAL_REASON",
  ERecruitment_UserWithdrawalEmail = "ERECRUITMENT::USER_WITHDRAWAL_EMAIL",
  ERecruitment_UserOAuth2Info = "ERECRUITMENT::USER_OAUTH2_INFO",
  ERecruitment_AuthToken = "ERECRUITMENT::AUTH_TOKEN",
  ERecruitment_Category = "ERECRUITMENT::CATEGORY",
  ERecruitment_Location = "ERECRUITMENT::LOCATION",
  ERecruitment_Affiliation = "ERECRUITMENT::AFFILIATION",
  ERecruitment_Study = "ERECRUITMENT::STUDY",
  ERecruitment_StudyExternalLink = "ERECRUITMENT::STUDY_EXTERNAL_LINK",
  ERecruitment_StudyAdditionalContent = "ERECRUITMENT::STUDY_ADDITIONAL_CONTENT",
  ERecruitment_StudyCategory = "ERECRUITMENT::STUDY_CATEGORY",
  ERecruitment_StudySponsor = "ERECRUITMENT::STUDY_SPONSOR",
  ERecruitment_StudySite = "ERECRUITMENT::STUDY_SITE",
  ERecruitment_StudyInquiry = "ERECRUITMENT::STUDY_INQUIRY",
  ERecruitment_StudyAttachment = "ERECRUITMENT::STUDY_ATTACHMENT",
  ERecruitment_PrescreeningConsent = "ERECRUITMENT::PRESCREENING_CONSENT",
  ERecruitment_PrescreeningQuestionnaire = "ERECRUITMENT::PRESCREENING_QUESTIONNAIRE",
  ERecruitment_PrescreeningQuestionnaireQuestion = "ERECRUITMENT::PRESCREENING_QUESTIONNAIRE_QUESTION",
  ERecruitment_PrescreeningQuestionnaireAnswer = "ERECRUITMENT::PRESCREENING_QUESTIONNAIRE_ANSWER",
  ERecruitment_Notice = "ERECRUITMENT::NOTICE",
  ERecruitment_LegalConsent = "ERECRUITMENT::LEGAL_CONSENT",
  ERecruitment_FAQ = "ERECRUITMENT::FAQ",
  ERecruitment_CandidatePool = "ERECRUITMENT::CANDIDATE_POOL",
  ERecruitment_CandidatePoolInterestDisease = "ERECRUITMENT::CANDIDATE_POOL_INTEREST_DISEASE",
  ERecruitment_CandidatePoolProvince = "ERECRUITMENT::CANDIDATE_POOL_PROVINCE",
  ERecruitment_CandidatePoolCategory = "ERECRUITMENT::CANDIDATE_POOL_CATEGORY",
  ERecruitment_Participation = "ERECRUITMENT::PARTICIPATION",
  ERecruitment_ParticipationAnswer = "ERECRUITMENT::PARTICIPATION_ANSWER",
  ERecruitment_ParticipationStatusHistory = "ERECRUITMENT::PARTICIPATION_STATUS_HISTORY",
  ERecruitment_Space = "ERECRUITMENT::SPACE",
  ERecruitment_SpaceStudy = "ERECRUITMENT::SPACE_STUDY",
  ERecruitment_SpaceStudyMember = "ERECRUITMENT::SPACE_STUDY_MEMBER",
  ERecruitment_SpaceStudyMemberPrivilege = "ERECRUITMENT::SPACE_STUDY_MEMBER_PRIVILEGE",
  ERecruitment_SpaceMember = "ERECRUITMENT::SPACE_MEMBER",
  ERecruitment_Inquiry = "ERECRUITMENT::INQUIRY",
  ERecruitment_InquiryAnswer = "ERECRUITMENT::INQUIRY_ANSWER",
  ERecruitment_PartnershipInquiry = "ERECRUITMENT::PARTNERSHIP_INQUIRY",
  ERecruitment_PartnershipInquiryCategory = "ERECRUITMENT::PARTNERSHIP_INQUIRY_CATEGORY",
  ERecruitment_Notification = "ERECRUITMENT::NOTIFICATION",
  ERecruitment_NotificationFetchEvent = "ERECRUITMENT::NOTIFICATION_FETCH_EVENT",
  ERecruitment_Banner = "ERECRUITMENT::BANNER",

  // Portal
  Portal_User = "PORTAL::USER",
  Portal_UserInfo = "PORTAL::USER_INFO",
  Portal_UserPrivilege = "PORTAL::USER_PRIVILEGE",
  Portal_UserConsentHistory = "PORTAL::USER_CONSENT_HISTORY",
  Portal_UserVerificationCode = "PORTAL::USER_VERIFICATION_CODE",
  Portal_UserWithdrawalReason = "PORTAL::USER_WITHDRAWAL_REASON",
  Portal_UserWithdrawalEmail = "PORTAL::USER_WITHDRAWAL_EMAIL",
  Portal_UserOAuth2Info = "PORTAL::USER_OAUTH2_INFO",
  Portal_AuthToken = "PORTAL::AUTH_TOKEN",
  Portal_Notice = "PORTAL::NOTICE",
  Portal_LegalConsent = "PORTAL::LEGAL_CONSENT",
  Portal_FAQ = "PORTAL::FAQ",
  Portal_Inquiry = "PORTAL::INQUIRY",
  Portal_InquiryAnswer = "PORTAL::INQUIRY_ANSWER",

  // CONVERTER
  Converter_SdtmVariable = "CONVERTER::SDTM_VARIABLE",
  Converter_SdtmigDomain = "CONVERTER::SDTMIG_DOMAIN",
  Converter_SdtmigDomainVariable = "CONVERTER::SDTMIG_DOMAIN_VARIABLE",
  Converter_Study = "CONVERTER::STUDY",
  Converter_StudyDbSpec = "CONVERTER::STUDY_DB_SPEC",
  Converter_StudyDomain = "CONVERTER::STUDY_DOMAIN",
  Converter_StudyDomainVariable = "CONVERTER::STUDY_DOMAIN_VARIABLE",
  Converter_StudyRawDataset = "CONVERTER::STUDY_RAW_DATASET",
  Converter_StudyRawDatasetVariable = "CONVERTER::STUDY_RAW_DATASET_VARIABLE",
  Converter_StudySdtmigDomain = "CONVERTER::STUDY_SDTMIG_DOMAIN",
  Converter_StudySdtmigDomainVariable = "CONVERTER::STUDY_SDTMIG_DOMAIN_VARIABLE",
  Converter_StudySdtmigDomainReference = "CONVERTER::STUDY_SDTMIG_DOMAIN_REFERENCE",
  Converter_StudySdtmigDataset = "CONVERTER::STUDY_SDTMIG_DATASET",
  Converter_StudySdtmigDatasetCompression = "CONVERTER::STUDY_SDTMIG_DATASET_COMPRESSION",
  Converter_StudyCrf = "CONVERTER::STUDY_CRF",
  Converter_StudyCrfAttr = "CONVERTER::STUDY_CRF_ATTR",
  Converter_StudyCrfLabel = "CONVERTER::STUDY_CRF_LABEL",
  Converter_StudyCrfExport = "CONVERTER::STUDY_CRF_EXPORT",
  Converter_StudyValidationReport = "CONVERTER::STUDY_VALIDATION_REPORT",
  Converter_TemplateFile = "CONVERTER::TEMPLATE_FILE",
  Converter_ControlledTerminologyVersion = "CONVERTER::CONTROLLED_TERMINOLOGY_VERSION",
  Converter_ControlledTerminologyCode = "CONVERTER::CONTROLLED_TERMINOLOGY_CODE",
  Converter_ControlledTerminologyTerm = "CONVERTER::CONTROLLED_TERMINOLOGY_TERM",
  Converter_StudyControlledTerminologyCode = "CONVERTER::STUDY_CONTROLLED_TERMINOLOGY_CODE",
  Converter_StudyControlledTerminologyTerm = "CONVERTER::STUDY_CONTROLLED_TERMINOLOGY_TERM",
  Converter_StudyDefineXml = "CONVERTER::STUDY_DEFINE_XML",
  Converter_StudyDefineXmlInformation = "CONVERTER::STUDY_DEFINE_XML_INFORMATION",
  Converter_StudyDefineXmlStandard = "CONVERTER::STUDY_DEFINE_XML_STANDARD",
  Converter_StudyDefineXmlDictionary = "CONVERTER::STUDY_DEFINE_XML_DICTIONARY",
  Converter_StudyDefineXmlDataset = "CONVERTER::STUDY_DEFINE_XML_DATASET",
  Converter_StudyDefineXmlVariable = "CONVERTER::STUDY_DEFINE_XML_VARIABLE",
  Converter_StudyDefineXmlValueLevel = "CONVERTER::STUDY_DEFINE_XML_VALUE_LEVEL",
  Converter_StudyDefineXmlCodelist = "CONVERTER::STUDY_DEFINE_XML_CODELIST",
  Converter_StudyDefineXmlDocument = "CONVERTER::STUDY_DEFINE_XML_DOCUMENT",
  Converter_StudyDefineXmlMethod = "CONVERTER::STUDY_DEFINE_XML_METHOD",

  // VDR
  Vdr_Room = "VDR::ROOM",
  Vdr_Room_Ai_Search_History = "VDR::ROOM::AI_SEARCH_HISTORY",
  Vdr_RoomSettingTerm = "VDR::ROOM_SETTING_TERM",
  Vdr_RoomSettingWatermark = "VDR::ROOM_SETTING_WATERMARK",
  Vdr_RoomRole = "VDR::ROOM_ROLE",
  Vdr_RoomRoleSystemPrivilege = "VDR::ROOM_ROLE_SYSTEM_PRIVILEGE",
  Vdr_RoomGroup = "VDR::ROOM_GROUP",
  Vdr_RoomUser = "VDR::ROOM_USER",
  Vdr_RoomResource = "VDR::ROOM_RESOURCE",
  Vdr_RoomResourceAccessLog = "VDR::ROOM_RESOURCE_ACCESS_LOG",
  Vdr_RoomRoleResourcePrivilege = "VDR::ROOM_ROLE_RESOURCE_PRIVILEGE",
  Vdr_RoomQuestionTeam = "VDR::ROOM_QUESTION_TEAM",
  Vdr_RoomQuestionTeamMember = "VDR::ROOM_QUESTION_TEAM_MEMBER",
  Vdr_RoomQuestionThread = "VDR::ROOM_QUESTION_QUESTION_THREAD",
  Vdr_RoomQuestionThreadView = "VDR::ROOM_QUESTION_THREAD_VIEW",
  Vdr_RoomQuestionThreadComment = "VDR::ROOM_QUESTION_THREAD_COMMENT",
  Vdr_RoomQuestionThreadCommentAttachment = "VDR::ROOM_QUESTION_THREAD_COMMENT_ATTACHMENT",
  Vdr_RoomQuestionSetting = "VDR::ROOM_QUESTION_SETTING",
  Vdr_RoomDwHistory = "VDR::ROOM_DW_HISTORY",

  // X
  X_NotificationMail = "X::NOTIFICATION_MAIL",
  X_Role = "X::ROLE",
  X_UserRole = "X::USER_ROLE",
  X_Affiliation = "X::AFFILIATION",
  X_PrivilegeMember = "X::PRIVILEGE_MEMBER",
  X_PrivilegeMemberPrivilege = "X::PRIVILEGE_MEMBER_PRIVILEGE",
  X_ScopeTemplate = "X::SCOPE_TEMPLATE",
  X_ScopeTemplateRole = "X::SCOPE_TEMPLATE_ROLE",
  X_ScopeTemplateOrder = "X::SCOPE_TEMPLATE_ORDER",
  X_TaskTemplate = "X::TASK_TEMPLATE",
}

export enum RefStatus {
  Pending = "PENDING",
  Created = "CREATED",
  Deleted = "DELETED",
}

export enum ResourcePrivilege {
  Owner = "OWNER",
  Grant = "GRANT", // 권한을 부여하기 위한 권한 (RefResourceType의 Type과 1:1 대응되는 `${RefResourceType}:GRANT` 권한이 존재해야 함)
  Ghost = "GHOST",

  // Project
  Project_CreateProject = "PROJECT::CREATE_PROJECT",

  // Project > OrgFolder
  Project_OrgFolder_Grant = "PROJECT::ORG_FOLDER:GRANT",
  Project_OrgFolder_CreateFolder = "PROJECT::ORG_FOLDER:CREATE_FOLDER",
  Project_OrgFolder_View = "PROJECT::ORG_FOLDER:VIEW",
  Project_OrgFolder_Delete = "PROJECT::ORG_FOLDER:DELETE",

  // Project > Project
  Project_Project_Grant = "PROJECT::PROJECT:GRANT",
  Project_Project_CreateFolder = "PROJECT::PROJECT:CREATE_FOLDER",
  Project_Project_View = "PROJECT::PROJECT:VIEW",
  Project_Project_Delete = "PROJECT::PROJECT:DELETE",

  // Project > Folder
  Project_Folder_Grant = "PROJECT::FOLDER:GRANT",
  Project_Folder_CreateDocument = "PROJECT::FOLDER:CREATE_DOCUMENT",
  Project_Folder_CreateProposal = "PROJECT::FOLDER:CREATE_PROPOSAL",
  Project_Folder_CreateVariable = "PROJECT::FOLDER:CREATE_VARIABLE",
  Project_Folder_View = "PROJECT::FOLDER:VIEW",
  Project_Folder_ViewVariable = "PROJECT::FOLDER:VIEW_VARIABLE",
  Project_Folder_Edit = "PROJECT::FOLDER:EDIT",
  Project_Folder_EditVariable = "PROJECT::FOLDER:EDIT_VARIABLE",
  Project_Folder_Delete = "PROJECT::FOLDER:DELETE",
  Project_Folder_DeleteVariable = "PROJECT::FOLDER:DELETE_VARIABLE",

  // Docs > Template
  Docs_Template_Grant = "DOCS::TEMPLATE:GRANT",
  Docs_Template_Create = "DOCS::TEMPLATE:CREATE",
  Docs_Template_View = "DOCS::TEMPLATE:VIEW",
  Docs_Template_Edit = "DOCS::TEMPLATE:EDIT",
  Docs_Template_Delete = "DOCS::TEMPLATE:DELETE",

  // Docs > Folder
  Docs_Folder_Grant = "DOCS::FOLDER:GRANT",
  Docs_Folder_CreateFolder = "DOCS::FOLDER:CREATE_FOLDER",
  Docs_Folder_CreateVariable = "DOCS::FOLDER:CREATE_VARIABLE",
  Docs_Folder_CreateDocument = "DOCS::FOLDER:CREATE_DOCUMENT",
  Docs_Folder_CreateProposal = "DOCS::FOLDER:CREATE_PROPOSAL",
  Docs_Folder_View = "DOCS::FOLDER:VIEW",
  Docs_Folder_ViewVariable = "DOCS::FOLDER:VIEW_VARIABLE",
  Docs_Folder_Edit = "DOCS::FOLDER:EDIT",
  Docs_Folder_EditVariable = "DOCS::FOLDER:EDIT_VARIABLE",
  Docs_Folder_Delete = "DOCS::FOLDER:DELETE",
  Docs_Folder_DeleteVariable = "DOCS::FOLDER:DELETE_VARIABLE",

  // Docs > FolderFile
  Docs_FolderFile_Grant = "DOCS::FOLDER_FILE:GRANT",
  Docs_FolderFile_View = "DOCS::FOLDER_FILE:VIEW",
  Docs_FolderFile_Edit = "DOCS::FOLDER_FILE:EDIT",
  Docs_FolderFile_Delete = "DOCS::FOLDER_FILE:DELETE",

  // Docs > Proposal
  Docs_Proposal_Grant = "DOCS::PROPOSAL:GRANT",
  Docs_Proposal_CreateVersion = "DOCS::PROPOSAL:CREATE_VERSION",
  Docs_Proposal_CreateComment = "DOCS::PROPOSAL:CREATE_COMMENT",
  Docs_Proposal_LibrarySearch = "DOCS::PROPOSAL:LIBRARY_SEARCH",
  Docs_Proposal_View = "DOCS::PROPOSAL:VIEW",
  Docs_Proposal_ViewComment = "DOCS::PROPOSAL:VIEW_COMMENT",
  Docs_Proposal_Export = "DOCS::PROPOSAL:EXPORT",
  Docs_Proposal_Edit = "DOCS::PROPOSAL:EDIT",
  Docs_Proposal_Delete = "DOCS::PROPOSAL:DELETE",
  Docs_Proposal_DeleteComment = "DOCS::PROPOSAL:DELETE_COMMENT",

  // Docs > Document
  Docs_Document_Grant = "DOCS::DOCUMENT:GRANT",
  Docs_Document_View = "DOCS::DOCUMENT:VIEW",
  Docs_Document_Delete = "DOCS::DOCUMENT:DELETE",
  Docs_Document_CreateEsign = "DOCS::DOCUMENT:CREATE_ESIGN",

  // Docs > Sign
  Docs_DocumentSign_Cancel = "DOCS::DOCUMENT_SIGN:CANCEL",
  Docs_DocumentSign_Signer = "DOCS::DOCUMENT_SIGN:SIGNER",

  // Builder
  Builder_Study_Grant = "BUILDER::STUDY:GRANT",
  Builder_Study_View = "BUILDER::STUDY:VIEW",
  Builder_Study_Edit = "BUILDER::STUDY:EDIT",

  // Safety
  Safety_Workspace_Grant = "SAFETY::WORKSPACE:GRANT",
  Safety_Workspace_Edit = "SAFETY::WORKSPACE:EDIT",
  Safety_Workspace_Blinder = "SAFETY::WORKSPACE:BLINDER",
  Safety_Workspace_View = "SAFETY::WORKSPACE:VIEW",

  // SAFETY > Workspace Item
  Safety_WorkspaceItem_Grant = "SAFETY::WORKSPACE_ITEM:GRANT",
  Safety_WorkspaceItem_Edit = "SAFETY::WORKSPACE_ITEM:EDIT",
  Safety_WorkspaceItem_View = "SAFETY::WORKSPACE_ITEM:VIEW",

  // OCR > Template
  OCR_Template_Grant = "OCR::TEMPLATE:GRANT",
  OCR_Template_Create = "OCR::TEMPLATE:CREATE",
  OCR_Template_View = "OCR::TEMPLATE:VIEW",
  OCR_Template_Edit = "OCR::TEMPLATE:EDIT",
  OCR_Template_Delete = "OCR::TEMPLATE:DELETE",
}

// DTO

export type RefId = bigint;

export type CreateRefIdsResponse = RefId[];

export type CreateRefIdsRequest = CreateRefIdsRequestByCount | CreateRefIdsRequestByTypes;

export interface CreateRefIdsRequestByCount {
  count: number;
  service: RefServiceType;
  type: RefResourceType;
}

export interface CreateRefIdByType {
  service: RefServiceType;
  type: RefResourceType;
}

export interface CreateRefIdsRequestByTypes {
  resources: Array<CreateRefIdByType>;
}
