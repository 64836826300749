import { RefResourceType, RefServiceType } from "./Ref.dto";

export enum RefAuditAction {
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE",
  Read = "READ",
}

export enum RefAuditCategory {
  // Auth
  Auth_User = "AUTH::USER",
  Auth_Organization = "AUTH::ORGANIZATION",
  Auth_Role = "AUTH::ROLE",
  Auth_Notification = "AUTH::NOTIFICATION",
  Auth_DictionaryLicense = "AUTH::DICTIONARY_LICENSE",

  // SSO
  SSO_AuthToken = "SSO::AUTH_TOKEN",
  SSO_RequestToken = "SSO::REQUEST_TOKEN",
  SSO_Notification = "SSO::NOTIFICATION",
  SSO_UserNotification = "SSO::USER_NOTIFICATION",
  SSO_UserDevice = "SSO::USER_DEVICE",

  // Billing
  Billing_Package = "BILLING::PACKAGE",
  Billing_Subscription = "BILLING::SUBSCRIPTION",
  Billing_Purchase = "BILLING::PURCHASE",
  Billing_NotificationMail = "BILLING::NOTIFICATION_MAIL",
  Billing_SubscriptionStatusNoti = "BILLING::SUBSCRIPTION_STATUS_NOTI",

  // Docs
  Docs_FolderFile_View = "DOCS::FOLDER_FILE_VIEW",
  Docs_FolderFile_Download = "DOCS::FOLDER_FILE_DOWNLOAD",
  Docs_Folder_Signer_Download = "DOCS::FOLDER_SIGNER_DOWNLOAD",

  // Builder
  Builder_StudyInfo = "BUILDER::STUDY_INFO",
  Builder_StudyDraftPayload = "BUILDER::STUDY_DRAFT_PAYLOAD",
  Builder_StudyPublishedPayload = "BUILDER::STUDY_PUBLISHED_PAYLOAD",
  Builder_StudyTemplateCrfVersion = "BUILDER::STUDY_TEMPLATE_CRF_VERSION",
  // Builder Property (Settings)
  Builder_StudyGeneralProperty = "BUILDER::STUDY_GENERAL_PROPERTY",
  Builder_StudySubjectColumnsProperty = "BUILDER::STUDY_SUBJECT_COLUMNS_PROPERTY",
  Builder_StudyEngineVersionProperty = "BUILDER::STUDY_ENGINE_VERSION_PROPERTY",
  Builder_StudyBlankCrfHeaderProperty = "BUILDER::STUDY_BLANK_CRF_HEADER_PROPERTY",
  Builder_StudyBlankCrfCoverProperty = "BUILDER::STUDY_BLANK_CRF_COVER_PROPERTY",
  Builder_StudyUatCoverProperty = "BUILDER::STUDY_UAT_COVER_PROPERTY",
  Builder_StudyEcoaConfigTwoFactorAuth = "BUILDER::STUDY_ECOA_CONFIG_TWO_FACTOR_AUTH",
  Builder_StudyEcoaConfigNotice = "BUILDER::STUDY_ECOA_CONFIG_NOTICE",
  Builder_StudyEcoaConfigNoticePerPage = "BUILDER::STUDY_ECOA_CONFIG_NOTICE_PER_PAGE",
  Builder_StudyEcoaConfigNoticeContent = "BUILDER::STUDY_ECOA_CONFIG_NOTICE_CONTENT",
  Builder_StudyEcoaConfigPopupTitle = "BUILDER::STUDY_ECOA_CONFIG_POPUP_TITLE",
  Builder_StudyEcoaConfigPopupContent = "BUILDER::STUDY_ECOA_CONFIG_POPUP_CONTENT",
  Builder_StudyEcoaConfigMisc = "BUILDER::STUDY_ECOA_CONFIG_POPUP_MISC",
  Builder_StudyEcoaConfigFontCTR = "BUILDER::STUDY_ECOA_CONFIG_FONT_CONTROLLER",
  Builder_StudyPayloadFolder = "BUILDER::STUDY_PAYLOAD_FOLDER",
  Builder_StudyPayloadPage = "BUILDER::STUDY_PAYLOAD_PAGE",
  Builder_StudyPayloadVisit = "BUILDER::STUDY_PAYLOAD_VISIT",
  Builder_StudyPayloadSection = "BUILDER::STUDY_PAYLOAD_SECTION",
  Builder_StudyPayloadPageSectionItem = "BUILDER::STUDY_PAYLOAD_PAGE_SECTION_ITEM",
  Builder_StudyPayloadScheduleMatrix = "BUILDER::STUDY_PAYLOAD_SCHEDULE_MATRIX",
  Builder_StudyPayloadDomain = "BUILDER::STUDY_PAYLOAD_DOMAIN",
  Builder_StudyRole = "BUILDER::STUDY_ROLE",
  Builder_StudyRolePrivilege = "BUILDER::STUDY_ROLE_PRIVILEGE",
  Builder_StudyMedicalCoding = "BUILDER::STUDY_MEDICAL_CODING",
  Builder_StudyMedicalCodingColumn = "BUILDER::STUDY_MEDICAL_CODING_COLUMN",
  /** @deprecated CDMS_StudySite 사용 요망 */
  Builder_StudySite = "BUILDER::STUDY_SITE",
  Builder_StudySiteEnvironment = "BUILDER::STUDY_SITE_ENVIRONMENT",
  Builder_StudyDashboardConfig = "BUILDER::STUDY_DASHBOARD_CONFIG",
  Builder_StudyDashboardConfigSubjectStatus = "BUILDER::STUDY_DASHBOARD_CONFIG_SUBJECT_STATUS",
  Builder_StudyDashboardConfigSite = "BUILDER::STUDY_DASHBOARD_CONFIG_SITE",
  Builder_StudyStatusCaptureStatus = "BUILDER::STUDY_STATUS_CAPTURE_STAT",
  Builder_StudyLabTestItem = "BUILDER::STUDY_LAB_TEST_ITEM",
  Builder_StudyLabTestItemUnit = "BUILDER::STUDY_LAB_TEST_ITEM_UNIT",
  Builder_StudyTemplate = "BUILDER::STUDY_TEMPLATE",
  Builder_StudyPayloadLabTestItem = "BUILDER::STUDY_PAYLOAD_LAB_TEST_ITEM",
  Builder_StudyPayloadLabTestUnit = "BUILDER::STUDY_PAYLOAD_LAB_TEST_UNIT",
  Builder_StudyPayloadTrigger = "BUILDER::STUDY_PAYLOAD_TRIGGER",
  Builder_StudyPayloadTriggerVersion = "BUILDER::STUDY_PAYLOAD_TRIGGER_VERSION",
  Builder_StudyPayloadValidation = "BUILDER::STUDY_PAYLOAD_VALIDATION",
  Builder_MemberOrganizationRole = "BUILDER::MEMBER_ORGANIZATION_ROLE",
  Builder_OrganizationRole = "BUILDER::ORGANIZATION_ROLE",
  Builder_OrganizationRolePrivilege = "BUILDER::ORGANIZATION_ROLE_PRIVILEGE",
  Builder_TriggerTargetMapping = "BUILDER::TRIGGER_TARGET_MAPPING",

  // Safety
  Safety_Cache = "SAFETY::CACHE",
  Safety_Workspace = "SAFETY::WORKSPACE",
  Safety_WorkspaceMember = "SAFETY::WORKSPACE_MEMBER",
  Safety_WorkspaceRole = "SAFETY::WORKSPACE_ROLE",
  Safety_Submission = "SAFETY::SUBMISSION",

  // CDMS - legacy
  CrfData = "CRF:DATA",
  CrfQuery = "CRF:QUERY",
  CrfItemSdv = "CRF:ITEM:SDV",
  CrfItemFreezing = "CRF:ITEM:FREEZING",
  CrfItemLocking = "CRF:ITEM:LOCKING",
  CrfSdv = "CRF:SDV",
  CrfFreezing = "CRF:FREEZING",
  CrfEsign = "CRF:ESIGN",
  CrfSecondEsign = "CRF:SECOND_ESIGN",
  CrfLocking = "CRF:LOCKING",
  CdmsLabTest = "CDMS:LAB_TEST",
  CdmsLabNormalRange = "CDMS:LAB_NORMAL_RANGE",
  BuilderRtsmRandomization = "BUILDER:RTSM:RANDOMIZATION",
  BuilderRtsmIpm = "BUILDER:RTSM:IPM",
  PreSubjectReserved = "PRE_SUBJECT:RESERVED",

  // CDMS
  CDMS_StudySite = "CDMS::STUDY_SITE",
  CDMS_StudyIndependentEvaluation = "CDMS::STUDY_INDEPENDENT_EVALUATION",
  CDMS_StudySubjectEnabled = "CDMS::STUDY_SUBJECT:ENABLED",

  // Admin
  Admin_Notice = "ADMIN::NOTICE",
  Admin_Release = "ADMIN::RELEASE",
}

export enum RefAuditReason {
  Create = "CREATE",
  Update = "UPDATE",
  Delete = "DELETE",
  Read = "READ",
}

export class RefAudit {
  id: bigint;
  refId: bigint;
  service: RefServiceType;
  type: RefResourceType;
  action: RefAuditAction;
  before: string;
  after: string;
  actantId: bigint;
  reasonKey: string;
  reason: string;
  category: RefAuditCategory;
  createdAt: Date;
  reserved1: string;
  reserved2: string;
  reserved3: string;
  reserved4: string;
}
